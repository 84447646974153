import React from 'react';

function Footer() {
  return (
    <p className='footer'>
      <a href='./' className='userFooterLink'>
        make your own swftli
      </a>
      <br />© swftli.me 2020
    </p>
  );
}
export default Footer;
