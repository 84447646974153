import React from 'react';

function Footer() {
  return (
    <p className='footer'>
      <br />© swftli.me 2020
    </p>
  );
}
export default Footer;
